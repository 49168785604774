import { render, staticRenderFns } from "./Globalresources.vue?vue&type=template&id=30a2d642"
import script from "./Globalresources.vue?vue&type=script&lang=js"
export * from "./Globalresources.vue?vue&type=script&lang=js"
import style0 from "./Globalresources.vue?vue&type=style&index=0&id=30a2d642&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports