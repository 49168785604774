<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Globalresources" style="width:100vw">
  <div id="ada2c351">
    <div id="b56d5987">
      全局资源
    </div>
    <el-tabs id="aa5513e9" type="card">
      <el-tab-pane label="全局储存">
        <div id="d4e271da">
          <el-radio-group id="ac49f7a9" v-model="rdg_ac49f7a9">
            <el-radio-button id="a4ca02e6" label="全部">
            </el-radio-button>
            <el-radio-button id="a30a0246" label="开发">
            </el-radio-button>
            <el-radio-button id="a815f892" label="测试">
            </el-radio-button>
            <el-radio-button id="a55422b1" label="生产">
            </el-radio-button>
          </el-radio-group>
          <div id="ad640bde">
            <div id="a61592c4">
              <div id="a4da90c7">
                当前值
              </div>
              <div id="e36970ca">
                30
              </div>
            </div>
            <div id="a96ab426">
              <div id="a75da1c5">
                最大值
              </div>
              <div id="c080e500">
                100
              </div>
            </div>
          </div>
          <img src="../assets/img-ae31963a.png" id="ae31963a"/>
        </div>
      </el-tab-pane>
      <el-tab-pane label="数据中心储存">
        <div id="a7768eac">
          <div id="a2c9abb3">
            页面设计中～
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="数据库">
        <div id="a2f61f2c">
          <div id="a648f62b">
            <div id="a39bdfbe">
              <div id="a918ecdf">
                库数
              </div>
              <div id="ad60cfc4">
                100
              </div>
            </div>
            <div id="c11d524c">
              <div id="b49f3fe3">
                存储
              </div>
              <div id="c7e16dd8">
                300
              </div>
            </div>
            <div id="acc876a8">
              <div id="e95178c7">
                每秒读
              </div>
              <div id="a7cf1655">
                234
              </div>
            </div>
            <div id="ad5002da">
              <div id="a4c57b5c">
                每秒写
              </div>
              <div id="cab582cc">
                678
              </div>
            </div>
          </div>
          <div id="a1665f5c">
            库数
          </div>
          <el-select id="bea1a56b" placeholder="过去七天" v-model="val_bea1a56b">
            <el-option v-for="item in op_bea1a56b" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-f95a930e.png" id="f95a930e"/>
          <div id="d48dae8f">
            存储
          </div>
          <el-select id="edf7757c" placeholder="过去七天" v-model="val_edf7757c">
            <el-option v-for="item in op_edf7757c" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-b8533b1a.png" id="b8533b1a"/>
        </div>
      </el-tab-pane>
      <el-tab-pane label="物理机">
        <div id="af2f0b24">
          <div id="a31c9f6d">
            <div id="fdf62948">
              <div id="aa3f4a80">
                CPU负载
              </div>
              <div id="abfb946a">
                100
              </div>
            </div>
            <div id="a0145c41">
              <div id="a8b1265f">
                内存
              </div>
              <div id="af385dc6">
                300G
              </div>
            </div>
            <div id="c664954c">
              <div id="af6ceeca">
                网络流量
              </div>
              <div id="a46f5312">
                67G
              </div>
            </div>
            <div id="c77efff0">
              <div id="d47a1924">
                磁盘IO
              </div>
              <div id="a17bc307">
                100
              </div>
            </div>
            <div id="aca75f1e">
              <div id="affe2028">
                网络宽带
              </div>
              <div id="bfb3ef0f">
                678
              </div>
            </div>
            <div id="a75ca19c">
              <div id="a3ff5e1f">
                系统错误日志
              </div>
              <div id="aa05b338">
                6
              </div>
            </div>
          </div>
          <div id="f49a7fbe">
            CPU负载
          </div>
          <el-select id="ae6eedeb" placeholder="过去七天" v-model="val_ae6eedeb">
            <el-option v-for="item in op_ae6eedeb" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-a2196e6a.png" id="a2196e6a"/>
          <div id="ab2c169a">
            内存
          </div>
          <el-select id="af455eb5" placeholder="过去七天" v-model="val_af455eb5">
            <el-option v-for="item in op_af455eb5" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-a214bd65.png" id="a214bd65"/>
          <div id="ac9c644e">
            网络流量
          </div>
          <el-select id="ad014021" placeholder="过去七天" v-model="val_ad014021">
            <el-option v-for="item in op_ad014021" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-a57a6b48.png" id="a57a6b48"/>
          <div id="fae9dd4c">
            磁盘IO
          </div>
          <el-select id="b517b970" placeholder="过去七天" v-model="val_b517b970">
            <el-option v-for="item in op_b517b970" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-aae8ea95.png" id="aae8ea95"/>
        </div>
      </el-tab-pane>
      <el-tab-pane label="虚拟机">
        <div id="b4edc980">
          <div id="a4322370">
            <div id="d53c91e4">
              <div id="af86e3a4">
                CPU负载
              </div>
              <div id="ff61ece2">
                100
              </div>
            </div>
            <div id="c5ed8af8">
              <div id="ac73e23b">
                内存
              </div>
              <div id="ab2c0478">
                300G
              </div>
            </div>
            <div id="cbb2ba27">
              <div id="c1e021e1">
                网络流量
              </div>
              <div id="a8bb3fa3">
                67G
              </div>
            </div>
            <div id="ca0f684a">
              <div id="a84ce463">
                磁盘IO
              </div>
              <div id="a0f2ed17">
                100
              </div>
            </div>
            <div id="aabbd1db">
              <div id="a157f346">
                网络宽带
              </div>
              <div id="aa4c62cb">
                678
              </div>
            </div>
            <div id="a13de8e9">
              <div id="a9f141c2">
                系统错误日志
              </div>
              <div id="a9cafc99">
                6
              </div>
            </div>
          </div>
          <div id="b07a3a29">
            CPU负载
          </div>
          <el-select id="fcd67aba" placeholder="过去七天" v-model="val_fcd67aba">
            <el-option v-for="item in op_fcd67aba" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-ab3cea3c.png" id="ab3cea3c"/>
          <div id="c22f3c49">
            内存
          </div>
          <el-select id="ece9d5a5" placeholder="过去七天" v-model="val_ece9d5a5">
            <el-option v-for="item in op_ece9d5a5" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-a8ecc04f.png" id="a8ecc04f"/>
          <div id="a2f2f57f">
            网络流量
          </div>
          <el-select id="bb40b359" placeholder="过去七天" v-model="val_bb40b359">
            <el-option v-for="item in op_bb40b359" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-de7511f2.png" id="de7511f2"/>
          <div id="c1f161c5">
            磁盘IO
          </div>
          <el-select id="a42f187d" placeholder="过去七天" v-model="val_a42f187d">
            <el-option v-for="item in op_a42f187d" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-d14439be.png" id="d14439be"/>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
    rdg_ac49f7a9:'',
    op_bea1a56b:[],
    val_bea1a56b:'',
    op_edf7757c:[],
    val_edf7757c:'',
    op_ae6eedeb:[],
    val_ae6eedeb:'',
    op_af455eb5:[],
    val_af455eb5:'',
    op_ad014021:[],
    val_ad014021:'',
    op_b517b970:[],
    val_b517b970:'',
    op_fcd67aba:[],
    val_fcd67aba:'',
    op_ece9d5a5:[],
    val_ece9d5a5:'',
    op_bb40b359:[],
    val_bb40b359:'',
    op_a42f187d:[],
    val_a42f187d:''
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#ada2c351{
  width:1648px;
  position:relative;
  margin-left:1px;
}
#ada2c351{
  background-color:rgb(252,252,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:4px 4px 4px rgba(0,0,0,0.16);
}
#b56d5987{
  padding-top:100px;
}
#b56d5987{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#aa5513e9{
  width:1330px;
  margin-top:30px;
  padding-left:166px;
  padding-bottom:1579px;
}
#aa5513e9{
  vertical-align: top;
}
#aa5513e9 /deep/ .el-tabs__item{
  font-size:22px;
  font-family:PingFang SC;
}
#d4e271da{
  width:1322px;
}
#ac49f7a9{
  width:764px;
  padding-left:276px;
}
#ac49f7a9{
  width:auto;
  display:inline-block;
  vertical-align: middle;
}
#a4ca02e6{
  width:173px;
  margin-left:0px;
  margin-top:0px;
  margin-bottom:0px;
  display:inline-block;
  vertical-align: middle;
}
#a4ca02e6{
  background-color:rgb(111,116,132,1);
  border-radius:6px 6px 6px 6px;
}
#a4ca02e6 .el-radio-button__inner{
  width:173px;
  height:43px;
  line-height:43px;
  font-size:16px;
  font-family:PingFang SC;
  padding:0px;
}
#a30a0246{
  width:172px;
  margin-left:25px;
  margin-top:0px;
  margin-bottom:0px;
  display:inline-block;
  vertical-align: middle;
}
#a30a0246{
  background-color:rgb(255,255,255,1);
  border:2px solid #6f7484;
  border-radius:6px 6px 6px 6px;
}
#a30a0246 .el-radio-button__inner{
  width:172px;
  height:43px;
  line-height:43px;
  font-size:16px;
  font-family:PingFang SC;
  padding:0px;
}
#a815f892{
  width:172px;
  margin-left:25px;
  margin-top:0px;
  margin-bottom:0px;
  display:inline-block;
  vertical-align: middle;
}
#a815f892{
  background-color:rgb(255,255,255,1);
  border:2px solid #6f7484;
  border-radius:6px 6px 6px 6px;
}
#a815f892 .el-radio-button__inner{
  width:172px;
  height:43px;
  line-height:43px;
  font-size:16px;
  font-family:PingFang SC;
  padding:0px;
}
#a55422b1{
  width:172px;
  margin-left:25px;
  margin-top:0px;
  margin-bottom:0px;
  display:inline-block;
  vertical-align: middle;
}
#a55422b1{
  background-color:rgb(255,255,255,1);
  border:2px solid #6f7484;
  border-radius:6px 6px 6px 6px;
}
#a55422b1 .el-radio-button__inner{
  width:172px;
  height:43px;
  line-height:43px;
  font-size:16px;
  font-family:PingFang SC;
  padding:0px;
}
#ad640bde{
  width:520px;
  position:relative;
  margin-top:80px;
  padding-left:391px;
}
#a61592c4{
  width:203px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#a61592c4{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a4da90c7{
  padding-top:20px;
}
#a4da90c7{
  text-align:center;
  color:#141620;
  font-size:16px;
  font-family:PingFang SC;
}
#e36970ca{
  margin-top:20px;
  padding-bottom:23px;
}
#e36970ca{
  text-align:center;
  color:#141620;
  font-size:27px;
  font-family:PingFang SC;
}
#a96ab426{
  width:203px;
  position:relative;
  margin-left:114px;
  display:inline-block;
  vertical-align: middle;
}
#a96ab426{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a75da1c5{
  padding-top:20px;
}
#a75da1c5{
  text-align:center;
  color:#141620;
  font-size:16px;
  font-family:PingFang SC;
}
#c080e500{
  margin-top:20px;
  padding-bottom:23px;
}
#c080e500{
  text-align:center;
  color:#141620;
  font-size:27px;
  font-family:PingFang SC;
}
#ae31963a{
  width:1322px;
  height:518px;
  margin-top:80px;
}
#ae31963a{
  vertical-align:middle;
}
#a7768eac{
  width:1322px;
}
#a7768eac{
  background-color:rgb(252,252,255,1);
  border-radius:0px 0px 0px 0px;
}
#a2c9abb3{
  padding-top:155px;
  padding-bottom:1043px;
}
#a2c9abb3{
  text-align:center;
  color:#c9c9c9;
  font-size:40px;
  font-family:PingFang SC;
}
#a2f61f2c{
  width:1322px;
}
#a648f62b{
  width:1314px;
  position:relative;
}
#a39bdfbe{
  width:203px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#a39bdfbe{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a918ecdf{
  padding-top:20px;
}
#a918ecdf{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#ad60cfc4{
  margin-top:18px;
  padding-bottom:21px;
}
#ad60cfc4{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#c11d524c{
  width:203px;
  position:relative;
  margin-left:168px;
  display:inline-block;
  vertical-align: middle;
}
#c11d524c{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#b49f3fe3{
  padding-top:20px;
}
#b49f3fe3{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#c7e16dd8{
  margin-top:18px;
  padding-bottom:21px;
}
#c7e16dd8{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#acc876a8{
  width:203px;
  position:relative;
  margin-left:167px;
  display:inline-block;
  vertical-align: middle;
}
#acc876a8{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#e95178c7{
  padding-top:20px;
}
#e95178c7{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#a7cf1655{
  margin-top:18px;
  padding-bottom:21px;
}
#a7cf1655{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#ad5002da{
  width:203px;
  position:relative;
  margin-left:167px;
  display:inline-block;
  vertical-align: middle;
}
#ad5002da{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a4c57b5c{
  padding-top:20px;
}
#a4c57b5c{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#cab582cc{
  margin-top:18px;
  padding-bottom:21px;
}
#cab582cc{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a1665f5c{
  margin-top:60px;
}
#a1665f5c{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a2f61f2c .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#f95a930e{
  width:1322px;
  height:518px;
  margin-top:30px;
  padding-left:0px;
}
#f95a930e{
  vertical-align:middle;
}
#d48dae8f{
  margin-top:60px;
}
#d48dae8f{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a2f61f2c .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#b8533b1a{
  width:1322px;
  height:518px;
  margin-top:30px;
  padding-left:0px;
}
#b8533b1a{
  vertical-align:middle;
}
#af2f0b24{
  width:1322px;
}
#a31c9f6d{
  width:1314px;
  position:relative;
  padding-left:1px;
}
#fdf62948{
  width:203px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#fdf62948{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#aa3f4a80{
  padding-top:20px;
}
#aa3f4a80{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#abfb946a{
  margin-top:18px;
  padding-bottom:21px;
}
#abfb946a{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a0145c41{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#a0145c41{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a8b1265f{
  padding-top:20px;
}
#a8b1265f{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#af385dc6{
  margin-top:18px;
  padding-bottom:21px;
}
#af385dc6{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#c664954c{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#c664954c{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#af6ceeca{
  padding-top:20px;
}
#af6ceeca{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#a46f5312{
  margin-top:18px;
  padding-bottom:21px;
}
#a46f5312{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#c77efff0{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#c77efff0{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#d47a1924{
  padding-top:20px;
}
#d47a1924{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#a17bc307{
  margin-top:18px;
  padding-bottom:21px;
}
#a17bc307{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#aca75f1e{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#aca75f1e{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#affe2028{
  padding-top:20px;
}
#affe2028{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#bfb3ef0f{
  margin-top:18px;
  padding-bottom:21px;
}
#bfb3ef0f{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a75ca19c{
  width:203px;
  position:relative;
  margin-left:20px;
  display:inline-block;
  vertical-align: middle;
}
#a75ca19c{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a3ff5e1f{
  padding-top:20px;
}
#a3ff5e1f{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#aa05b338{
  margin-top:18px;
  padding-bottom:21px;
}
#aa05b338{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#f49a7fbe{
  margin-top:60px;
}
#f49a7fbe{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#af2f0b24 .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#a2196e6a{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#a2196e6a{
  vertical-align:middle;
}
#ab2c169a{
  margin-top:60px;
}
#ab2c169a{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#af2f0b24 .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#a214bd65{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#a214bd65{
  vertical-align:middle;
}
#ac9c644e{
  margin-top:60px;
}
#ac9c644e{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#af2f0b24 .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#a57a6b48{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#a57a6b48{
  vertical-align:middle;
}
#fae9dd4c{
  margin-top:60px;
}
#fae9dd4c{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#af2f0b24 .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#aae8ea95{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#aae8ea95{
  vertical-align:middle;
}
#b4edc980{
  width:1322px;
}
#a4322370{
  width:1314px;
  position:relative;
  padding-left:1px;
}
#d53c91e4{
  width:203px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#d53c91e4{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#af86e3a4{
  padding-top:20px;
}
#af86e3a4{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#ff61ece2{
  margin-top:18px;
  padding-bottom:21px;
}
#ff61ece2{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#c5ed8af8{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#c5ed8af8{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#ac73e23b{
  padding-top:20px;
}
#ac73e23b{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#ab2c0478{
  margin-top:18px;
  padding-bottom:21px;
}
#ab2c0478{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#cbb2ba27{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#cbb2ba27{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#c1e021e1{
  padding-top:20px;
}
#c1e021e1{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#a8bb3fa3{
  margin-top:18px;
  padding-bottom:21px;
}
#a8bb3fa3{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#ca0f684a{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#ca0f684a{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a84ce463{
  padding-top:20px;
}
#a84ce463{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#a0f2ed17{
  margin-top:18px;
  padding-bottom:21px;
}
#a0f2ed17{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#aabbd1db{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#aabbd1db{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a157f346{
  padding-top:20px;
}
#a157f346{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#aa4c62cb{
  margin-top:18px;
  padding-bottom:21px;
}
#aa4c62cb{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a13de8e9{
  width:203px;
  position:relative;
  margin-left:20px;
  display:inline-block;
  vertical-align: middle;
}
#a13de8e9{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a9f141c2{
  padding-top:20px;
}
#a9f141c2{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#a9cafc99{
  margin-top:18px;
  padding-bottom:21px;
}
#a9cafc99{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#b07a3a29{
  margin-top:60px;
}
#b07a3a29{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#b4edc980 .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#ab3cea3c{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#ab3cea3c{
  vertical-align:middle;
}
#c22f3c49{
  margin-top:61px;
}
#c22f3c49{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#b4edc980 .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#a8ecc04f{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#a8ecc04f{
  vertical-align:middle;
}
#a2f2f57f{
  margin-top:60px;
}
#a2f2f57f{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#b4edc980 .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#de7511f2{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#de7511f2{
  vertical-align:middle;
}
#c1f161c5{
  margin-top:60px;
}
#c1f161c5{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#b4edc980 .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#d14439be{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#d14439be{
  vertical-align:middle;
}
/*在此注释下方添加自定义样式*/
</style>
